import React from 'react'
import moment from 'moment-timezone'
import axios from 'axios'
import { pick } from 'lodash'
const debug = process.env.REACT_APP_APP_STAGE === 'local' ? require('debug')('lp:booking-provider') : () => {}

const BookingContext = React.createContext({
  now: moment(),

  clinic: {
    id: null,
    name: null,
  },

  setClinic: () => {},
  setGroup: () => {},
  setPractitioner: () => {},
  setTime: () => {},
  setSuburb: () => {},
  setDay: () => {},
  setMonth: () => {},

  suburb: null,

  suburbs: [],
  setSuburbs: () => {},

  predictedSuburb: null,
  setPredictedSuburb: () => {},

  availability: null,
  rangesLoading: null,

  submit: () => {},

  groups: null,
  practitioners: null,
  group: null,
  practitioner: null,
  time: null,
  day: null,
  month: null,
  state: {},
})

const nullState = () => ({
  predictedSuburb: null,
  groups: null,
  practitioners: null,
  group: null,
  practitioner: null,
  time: null,
  day: null,
})

export default function BookingFormProvider ({
  ...props
}) {
  const gaEvent = (e) => props.isOpen && _gaEvent(e)

  const now = moment().startOf('day')
  const [month, setMonth] = React.useState(now.clone().endOf('month'))

  const [state, setState] = React.useState(nullState())
  const [clinic, _setClinic] = React.useState({
    id: null,
    name: null,
    address: null,
    phone: null,
    location: null,
    provider: null,
    providerData: null,
  })
  const [availability, setAvailability] = React.useState(null)
  const [rangesLoaded, setRangesLoaded] = React.useState([])
  const [rangesLoading, setRangesLoading] = React.useState(null)
  const [suburb, _setSuburb] = React.useState(null)
  const [suburbs, _setSuburbs] = React.useState([])
  const [predictedSuburb, _setPredictedSuburb] = React.useState(null)

  function setClinic (c) {
    resetAvailability()
    setState(nullState())

    if (!c) {
      setClinic({
        id: null,
        name: null,
        address: null,
        phone: null,
        location: null,
        provider: null,
        providerData: null,
      })
    } else {
      const {
        id,
        name,
        address,
        phone = null,
        location = null,
        provider = 'typeform',
        providerData = {}
      } = c
      _setClinic({
        id, name, provider, providerData, address, phone, location
      })
    }
  }

  function setSuburb (s) {
    setClinic(null)
    _setSuburb(s)
  }

  function setSuburbs (s) {
    resetAvailability()
    setState(nullState())
    _setSuburbs(s)
  }

  function setPredictedSuburb (s) {
    setSuburb(null)
    _setPredictedSuburb(s)
  }

  const {
    groups,
    practitioners,
    group,
    practitioner,
    time,
    day,
  } = state

  function resetAvailability () {
    setMonth(now.clone().endOf('month'))
    if (rangesLoading) {
      rangesLoading.source.cancel()
      setRangesLoading(null)
    }
    setRangesLoaded([])
    setAvailability(null)
  }

  function setConfig (config) {
    setState({
      ...nullState(),
      group,
      practitioners: config.practitioners,
      groups: config.groups,
			url: config.url
    })
  }
  function setGroup (g) {
    if (group === g) return;
    resetAvailability()
    setState({ ...state, group: g, practitioner: null, time: null, day: null })
  }
  function setPractitioner (p) {
    if (practitioner === p) return;
    resetAvailability()
    setState({ ...state, practitioner: p, time: null, day: null })
  }
  function setDay (d) {
    setState({ ...state, day: d, time: null })
  }
  function setTime (t) {
    setState({ ...state, time: t })
  }

  React.useEffect(() => {
    // debug('Mounted')
    if (!clinic || !clinic.id || clinic.provider === 'typeform') return;
    axios.get(`${process.env.REACT_APP_API_URL}/appointments/${clinic.id}/_config`)
      .then(r => {
				switch(clinic.provider) {
					case 'CLINIKO':
						setConfig({
							...r.data,
							groups: r.data.appointmentTypes.reduce((r, a) => {
								if (a.initial) r.initial.push(a)
								if (a.repeating) r.repeating.push(a)
								return r
							}, { initial: [], repeating: [] })
						})
						break;
					case 'IFRAME':
						setConfig(r.data);
				}
			})
  }, [clinic])


  const submit = async (userData) => {
    if (userData.remember) window.localStorage.setItem('userData', JSON.stringify(userData))
    else window.localStorage.removeItem('userData')

    const dob = moment(userData.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD')+'T00:00:00Z'
    const payload = {
      practitionerId: Number(time.practitionerIds[0]),
      appointmentTypeId: Number(time.appointmentTypeIds[0]),
      appointmentTime: time.ts,
      group,
      ...userData,
      dateOfBirth: dob,
    }

    return axios.post(`${process.env.REACT_APP_API_URL}/appointments/${clinic.id}`, payload)
  }

  React.useEffect(() => {
    // debug('af: Availability fetch effect')
    let source = null
    let clean = () => source && source.cancel()
    if (!clinic || clinic.provider === 'typeform') {
      // debug('af: Invalid clinic')
      return clean;
    }
    if (!group || !groups) {
      // debug('af: No group')
      return clean;
    }
    if (group === 'repeating' && practitioner === null) {
      // debug('af: No practitioner')
      return clean;
    }

    const appointmentTypeIds = groups[group].map(g => g.id)
    const startDate = Math.max(now.unix(), month.clone().startOf('month').unix())
    const endDate = month.unix()

    if (startDate >= endDate) {
      // debug('af: Invalid date range %s -> %s', startDate, endDate)
      return clean;
    }

    const k = [startDate,endDate].join('-')
    if (rangesLoaded.indexOf(k) !== -1) {
      // debug('af: Range %s was loaded', k)
      return;
    }

    if (rangesLoading) {
      // debug('af: Ranges already loading')
      if (rangesLoading.k === k) {
        // debug('af: Ranges key matches, return')
        return;
      }
      else {
        // debug('af: Cancelling ranges loading')
        rangesLoading.source.cancel()
      }
    }

    source = axios.CancelToken.source()
    setRangesLoading({
      source,
      k,
    })

    // debug('af: Fetching: %j', source)
    axios.get(`${process.env.REACT_APP_API_URL}/appointments/${clinic.id}/_availability`, {
      cancelToken: source.token,
      params: {
        group,
        practitionerIds: practitioner,
        appointmentTypeIds: appointmentTypeIds.join(','),
        startDate,
        endDate,
      }
    }).catch(e => {
      console.log('Error')
      console.log(e)
    }).then(r => {
      if (!r) return;
      // debug('af: Fetched: %j, %j', source, r)
      setRangesLoaded([...rangesLoaded, k])
      setAvailability({
        ...(availability || {}),
        ...r.data,
      })
      setRangesLoading(null)
      if (Object.keys(r.data).length === 0) {
        if (now.month() === month.month()) setMonth(now.clone().add(1, 'month').endOf('month'))
      }
    })
  }, [state, month, rangesLoaded, rangesLoading])

  const {
    id: clinicId,
    name: clinicName,
  } = React.useMemo(() => {
    return clinic ? clinic : {
      id: null,
      name: null,
    }
  }, [clinic])

  return <BookingContext.Provider
    value={{
      now,

      clinic,
      clinicId,
      clinicName,

      setClinic,
      setGroup,
      setPractitioner,
      setTime,
      setSuburb,
      setDay,
      setMonth,

      suburb,

      suburbs,
      setSuburbs,

      predictedSuburb,
      setPredictedSuburb,

      availability,
      rangesLoading,

      submit,

      groups,
      practitioners,
      group,
      practitioner,
      time,
      day,
      month,
      state,
    }}
    {...props}
  />
}

export const useBooking = () => React.useContext(BookingContext)
