import React from 'react'

if (typeof window !== 'undefined') window.initMap = () => {}

export const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&callback=initMap&libraries=places&v=weekly`
import omit from 'lodash/omit'
import debounce from 'debounce-promise'

const getPlacePredictionsDebounced = debounce((request, autocomplete) => new Promise(resolve => {
  autocomplete && autocomplete.getPlacePredictions(request, result => resolve(result))
}), 1000, { trailing: true })

const GoogleMapContext = React.createContext({
  placesService: null,
  autocompleteService: null,

  getPlacePredictions: () => {},
  getDetails: () => {},

  scriptLoaded: typeof google !== 'undefined',
})

export default function GoogleMapProvider ({ children, ...props }) {
  const [scriptLoaded, setScriptLoaded] = React.useState(typeof google !== 'undefined')

  const mapDiv = React.useRef(null)
  const autocomplete = React.useRef(
    typeof google !== 'undefined' ? new google.maps.places.AutocompleteService() : null
  )
  const placesService = React.useRef(null)

  if (typeof window !== 'undefined') {
    React.useEffect(() => {
      window.initMap = () => {
        setScriptLoaded(true)
      }
      return () => window.initMap = undefined
    }, [])

    React.useEffect(() => {
      if (scriptLoaded && mapDiv.current) {
        if (!placesService.current) placesService.current = new window['google'].maps.places.PlacesService(mapDiv.current)
      }
    }, [scriptLoaded, mapDiv])

    React.useEffect(() => {
      if (scriptLoaded) {
        if (!autocomplete.current) autocomplete.current = new window['google'].maps.places.AutocompleteService()
        return;
      }
      const scripts = document.getElementsByTagName('script')
      for (let { src } of scripts) {
        if (src === scriptSrc) {
          if (!scriptLoaded) {
            setScriptLoaded(typeof google !== 'undefined')
          }
          return;
        }
      }

      const script = document.createElement('script');
      script.src = scriptSrc
      script.defer = true
      script.async = true

      document.head.appendChild(script);
    }, [scriptLoaded])
  }

  const getPlacePredictions = (request) => {
    return getPlacePredictionsDebounced(request, autocomplete.current)
  }

  const getDetails = (request) => {
    return new Promise(resolve => {
      placesService.current && placesService.current.getDetails(request, place => resolve({
        name: place.name,
        place_id: place.place_id,
        geometry: place.geometry,
        address_components: place.address_components,
      }))
    })
  }

  return (
    <GoogleMapContext.Provider value={{
      placesService: placesService.current,
      autocompleteService: autocomplete.current,

      getPlacePredictions,
      getDetails,

      scriptLoaded,
    }}>
      <div ref={mapDiv} style={{ display: 'none' }}/>
      {children}
    </GoogleMapContext.Provider>
  )
}

export const useGoogleMap = () => React.useContext(GoogleMapContext)

